<template>
  <div class="dry-service">
    <moduleTitle title="烘干服务"></moduleTitle>
    <!-- 整个 -->
    <div class="zongti">
      <div class="dbox" v-for="item in dustList" :key="item.id">
        <div class="box" :class="getClass(item.dustSystemStatus)"></div>
        <!-- <div class="box box2" :class="getClass1()"></div> -->
        <div class="text">{{ item.dustSystem }}</div>
        <!-- <div class="text cchen">除尘系统2</div> -->
        <div>
          <el-switch class="switchStyle" style="display: block" v-model="item.dustSystemStatus" active-color="#03FFEF "
            inactive-color="#DCDCDC" active-text="开" inactive-text="关">
          </el-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleTitle from '../process/components/moduleTitle.vue'
import { getProcessEnvironment } from '../../../api/processApi/process';

export default {
  components: {
    moduleTitle
  },
  data() {
    return {
      dustList: [],
      flag: true,
      flag2: false,
    };
  },
  mounted() {
    this.getprocessenvironment()
  },
  methods: {

    getClass(val) {
      if (val === false) {
        return 'img1';
      } else return 'img';
    },
    //调用烘干服务接口 
    getprocessenvironment() {
      let name = '中诚加工厂区'
      getProcessEnvironment(name).then(res => {
        console.log(res);
        this.dustList = res.data.dustList
        this.name = this.dustList[0].dustSystem
        this.flag = this.dustList[0].dustSystemStatus
        this.name2 = this.dustList[1].dustSystem
        this.flag2 = this.dustList[1].dustSystemStatus
        console.log(this.name, this.flag);
        console.log(this.name2, this.flag2);
      }).catch(err => {
        console.log(err);
      })
    }


  }

};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.img {
  background-image: url(../../../assets/process-num/paifeng.png);
}

.img1 {
  background-image: url(../../../assets/process-num/paifengTrue.png);

}

.dry-service {
  position: relative;
  width: companyW(482vw);
  height: companyH(305vh);
  // background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border: 1px solid;
  border-image: linear-gradient(0deg, #7388C4, #7388C4) 10 10;
  // opacity: 0.74;

  .zongti {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: ompanyW(482vw);
    overflow: auto;
    height: companyH(322vh);

  }

  .dbox {
    display: flex;
    z-index: 999;
    flex-direction: column;
    align-items: center;
    // width: companyW(140vw);
    height: companyH(220vh);
    justify-content: space-between;

    .box {
      width: companyW(140vw);
      height: companyH(140vh);
      // margin-top: companyH(63vh);
      // margin-left: companyW(59vw);

      background-repeat: no-repeat;
      background-size: contain;
    }

    .box2 {
      margin-left: companyW(85vw);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .text {
    font-size: companyW(18vw);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    // margin-top: companyH(19vh);
    // margin-left: companyW(89vw);
  }

  .cchen {
    margin-left: companyW(144vw);
  }

  .dry-service::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 20, 36, 0.4);

    width: 100%;
    height: 100%;
    z-index: -1;
    /* 将伪元素放在父元素底部 */
    opacity: 0.74;
  }
}
</style>
