<template>
    <div class="dry-service">
      <moduleTitle
        title="视频监控"
        :TimeOptions="TimeOptions"
        :allData="allData"
        @getdarkroomId="getVideoData"
      >
      </moduleTitle>
      <!-- 
        <el-button
          type="success"
          @click="handleScreenshot"
          style="position: absolute; bottom: 0; left: 18px"
          size="mini"
          >截图</el-button
        >
        <el-button
          type="success"
          @click="handleRecording"
          :icon="recording ? 'el-icon-loading' : ''"
          style="position: absolute; bottom: 0; right: 18px"
          size="mini"
          >{{ recording ? "录制中" : "录制" }}</el-button
    
    
        > -->
  
      <div class="videobox" v-if="videosrc != ''">
        <video ref="videoPlayer" id="video" class="video-js">
          <source :src="videosrc" type="application/x-mpegURL" />
        </video>
      </div>
    </div>
  </template>
    
    <script>
  import "videojs-contrib-hls";
  import Videojs from "video.js";
  import "video.js/dist/video-js.css";
  
  var mediaRecorder;
  import moduleTitle from "./videoTitle.vue";
  import { getYiShiUrl, getByDarkroomId } from "@/api/seedlingApi/seedling";
  import { listProcess } from "@/api/njApi/honggan.js";
  
  export default {
    components: {
      moduleTitle,
    },
    data() {
      return {
        imgUrl: "", // 截图地址
        recording: false, // 是否录制中
        videoData: [], // 视频流数据
        videoUrl: "", // 录制的视频地址
        TimeOptions: [], //暗室数据
      
        videosrc: "",
        allData:[]
      };
    },
    created() {
      this.getanshiList();
    },
    mounted() {},
    methods: {
      getanshiList() {
        listProcess({
          tenantId: localStorage.getItem("tenantId"),
        })
          .then((res) => {
            if (res.code == 1) {
              this.darkroomId(res.data.id);
            }
          })
          .catch((err) => {});
      },
      darkroomId(val) {
        getByDarkroomId({
          darkroomId: val,
        }).then((res) => {
      
          this.TimeOptions = res.data.map((item) => {
            return {
              channelNo: item.channelNo,
              cameraName: item.cameraName,
            };
          });
          this.allData = res.data.map((item) => {
            return {
              cameraId: item.cameraId,
              channelNo: item.channelNo
            };
          });
  
          var loginVo = {
            cameraId: res.data[0].cameraId, //摄像头id
            channelNo: res.data[0].channelNo, //摄像头端口
          };
          this.getVideoData(loginVo);
        });
      },
      getVideoData(loginVo) {
          console.log(loginVo,789);
        this.videosrc = "";
        getYiShiUrl(loginVo).then((res) => {
          this.videosrc = res.data;
          this.$nextTick(() => {
            Videojs(this.$refs.videoPlayer, {
              muted: true, //muted : true/false 是否静音
              controls: false,
              BigplayButton: false,
              autoplay: true,
              fluid: true, //Video.js播放器将具有流畅的大小。换句话说，它将扩展以适应其容器。
              // aspectRatio: "10:6",
              aspectRatio: "16:9",
              fullscreen: {
                options: {
                  navigationUI: "hide",
                },
              },
              techOrder: ["html5"], // 兼容顺序
              flvjs: {
                mediaDataSource: {
                  isLive: false,
                  cors: true,
                  withCredentials: false,
                },
              },
            });
          });
        });
      },
  
      // 截图
      handleScreenshot() {
        let video = document.getElementById("video1"); // 获取video节点
        let canvas = document.createElement("canvas"); // 创建canvas节点
        console.log(canvas, 99999);
        let w = window.innerWidth;
        let h = (window.innerWidth / 16) * 9;
        canvas.width = w;
        canvas.height = h; // 设置宽高
        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, w, h); // video写入到canvas
        this.imgUrl = canvas.toDataURL("image/png"); // 生成截图地址
        var a = document.createElement("a");
        a.href = this.imgUrl;
        a.download = "screenshot.png";
        a.click();
      },
      // 录制
      handleRecording() {
        this.recording = !this.recording;
        if (this.recording) {
          this.$message.success("开始录制");
          mediaRecorder.start();
        } else {
          this.$message.success("结束录制");
          mediaRecorder.stop();
          this.showCapture();
        }
      },
      // video元数据加载后触发
      onloadedmetadata() {
        let video = document.getElementById("video1");
        mediaRecorder = new MediaRecorder(video.captureStream(25)); // 生成速率25的MediaRecorder对象
        const _this = this;
        mediaRecorder.ondataavailable = function (e) {
          _this.videoData.push(e.data); // 添加视频流
        };
      },
  
      // 返回视频地址
      showCapture() {
        return new Promise((resolve) => {
          setTimeout(() => {
            let blob = new Blob(this.videoData, {
              type: "video/mp4",
            });
            this.videoUrl = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = this.videoUrl;
            a.download = "my-video.webm";
            a.click();
            resolve();
          });
        });
      },
    },
  };
  </script>
    
    <style  lang="scss" scoped>
  @function companyW($num) {
    @return ($num * 100)/1920;
  }
  
  @function companyH($num) {
    @return ($num * 100)/1080;
  }
  
  .dry-service {
    position: relative;
  
    width: companyW(482vw);
    height: companyH(342vh);
    background: rgba(0, 20, 36, 0.4);
    // background-color: blue;
    border: companyW(1vw) solid;  border-image: linear-gradient(0deg, #7388C4, #7388C4) 10 10;

  
    // opacity: 0.74;
    z-index: 999;
  
    .videobox {
      position: absolute;
      left: companyW(18vw);
      top: companyH(67vh);
      width: companyW(448vw);
      height: companyH(232vh);
    }
  }
  </style>
    