import request from '@/utils/request.js';

// 加工能力接口
export function getProcesspower(data) {
  return request({
    url:`/process/power?name=${ data }`,
    method:'get',
    data
  });
}
// 获取加工订单和已完成订单
export function getProcessOrderInfo(data) {
  return request({
    url:'/process/orderInfo',
    method:'get',
    data
  });
}
// 获取厂区环境
export function getProcessEnvironment(data) {
  return request({
    url:`/process/environment?name=${ data }`,
    method:'get',
    data
  });
}
// 获取加工机
export function getProcessCondition(data) {
  return request({
    url:`/process/condition?id=${ data }`,
    method:'get',
    data
  });
}
// 获取所有加工机
export function getProcessAllCondition(){
  return request({
    url:'/process/allCondition',
    method:'get',
  });
}
// 获取加工机不同时间的温度
export function getProcessTemperature(params) {
    return request({
        url: '/process/temperature',
        method: 'get',
        params,
    });
}

export function getEnvironmentDetail(params) {
  return request({
    url:'/process/getEnvironmentDetail',
    method:'get',
    params
  });
}

export function getAlarm(params) {
  return request({
    url:'/process/getAlarm',
    method:'get',
    params
  });
}
