<template>
  <div class="middle">
    <div class="middle-nav">
      <navList :Option="navList" />
    </div>
    <div class="middle-tray"></div>
    <div class="aa">
      <el-carousel height="40px" indicator-position="none" :interval="4000">
        <el-carousel-item
          v-for="item in tablelist"
          :key="item.name"
          style="display: flex; align-items: center; justify-content: center"
        >
          <div style="display: flex; justify-content: space-around">
            <div style="color: #fff; font-size: 20px; margin-right: 10px">
              {{ item.info }}
            </div>
            <div style="color: #fff; font-size: 20px">{{ item.date }}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 烘干摄像头1 -->

    <!-- <video
      id="video1"
      ref="video1"
      muted="muted"
      controls="controls"
      autoplay="autoplay"
      objectFit="cover"
    ></video> -->

    <!-- 烘干摄像头2 -->
    <!-- <video
      id="video2"
      ref="video2"
      muted="muted"
      controls="controls"
      autoplay="autoplay"
    ></video> -->
  </div>
</template>

<script>
import navList from "../components/navList.vue";
import { getAlarm, listProcess } from "@/api/processApi/process.js";
import {
  getByDarkroomId,
  loginAndPlayView,
} from "@/api/seedlingApi/seedling.js";

export default {
  components: {
    navList,
  },
  data() {
    return {
      navList,
      tablelist: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getAlarm({})
        .then((res) => {
          this.tablelist = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      // listProcess()
      //   .then((res) => {
      //     getByDarkroomId({ darkroomId: res.data.id })
      //       .then((res) => {
      //         var loginVo1 = {
      //           username: res.data[0].username, //摄像头登录账号
      //           password: res.data[0].password, //摄像头登录账号
      //           ip: res.data[0].ip, //摄像头ip
      //           port: res.data[0].port, //摄像头端口
      //           channelId: "1",
      //         };
      //         // var loginVo2 = {
      //         //   username: res.data[1].username, //摄像头登录账号
      //         //   password: res.data[1].password, //摄像头登录账号
      //         //   ip: res.data[1].ip, //摄像头ip
      //         //   port: res.data[1].port, //摄像头端口
      //         //   channelId: "1",
      //         // };
      //         this.getVideoData1(loginVo1);
      //         // this.getVideoData2(loginVo2);
      //       })
      //       .catch((err) => {});
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    getVideoData1(loginVo1) {
      loginAndPlayView(loginVo1).then((res) => {
        if (res.code == 200) {
          var userId = res.data.userId;
          var playId = res.data.playId;
          // this.loginVo.userId = userId;
          // this.loginVo.playId = playId;
          if (Wfs.isSupported()) {
            var video1 = this.$refs.video1;
            var wfs = new Wfs();
            wfs.attachMedia(video1, userId + "_" + playId, "H264Raw", userId); //绑定video标签
            //调试视频标签播放速度
            video1.playbackRate = 1.0;
          }
        } else {
          alert(
            "播放失败，错误信息：" +
              (res.data && res.data.loginMessage ? res.data.loginMessage : "")
          );
        }
      });
    },
  },
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.middle {
  position: relative;
  width: companyW(916vw);
  height: companyH(978vh);
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;

  .middle-nav {
    width: companyW(850vw);
    height: companyH(65vh);
    margin: 0 auto;
    margin-top: companyH(44vh);
  }

  .middle-tray {
    position: absolute;
    margin-left: companyW(-70vw);
    width: companyW(1092vw);
    height: companyH(230vh);
    z-index: 99999;
  }
}
.aa {
  width: companyW(850vw);
  height: companyH(40vh);
  margin: 0 auto;
  margin-top: companyH(20vh);
}

.el-carousel__item {
  // background-color: rgba(115, 136, 196, 0.5);
  background-image: url(../../../../assets/process-num/baojing.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.videoBox {
  width: companyW(850vw);
  height: companyH(478vh);
}
.shipinbox {
  margin-top: companyH(20vh);
  margin-bottom: companyH(20vh);
}
.bomBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: auto;
  max-height: companyH(250vh);
  width: companyW(850vw);
}
.smallvideoBox {
  width: companyW(272vw);
  height: companyH(165vh);
}
::-webkit-scrollbar {
  // 纵向滚动条和横向滚动条宽度
  width: companyW(10vw);
  height: companyH(10vh);
}
::-webkit-scrollbar-thumb {
  // 滚动条背景条样式
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #fff;
}
::-webkit-scrollbar-track {
  // 滚动条按钮样式
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  background: #5b5f63;
}
#video1
{
  // position: absolute;
  // left: companyW(18vw);
  // top: companyH(67vh);
  width: companyW(848vw);
  height: companyH(332vh);
  // background-color: #99c327;
  object-fit: fill;
}
</style>
