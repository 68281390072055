<template>
  <div
    class="title"
    :style="{ 'background-image': `url(${require('@/assets/'+typeOf+'/'+njTitle+'.png')})` }"
  >
    <div class="title-desc">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true,
    },
    // 图片名字
    njTitle: {
      type: String,
      default: '',
    },
    // 静态文件夹下文件名
    typeOf: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../../assets/font/np4SotSdlbui.woff);
}
.title {
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
  .title-desc {
    margin-top: companyH(11vh);
    text-align: center;
    height: companyH(40vh);
    font-size: companyH(42vh);
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #fefefe;
    line-height: 100%;
  }
}
</style>
