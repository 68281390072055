import request from '@/utils/request.js';
// import axios from 'axios';
// 获取烘干能力信息
export function dryingpower(params) {
  return request({
    url:'/drying/power',
    method:'get',
    params
  });
}
// 获取烘干服务及对比上年情况
export function dryingchart(params) {
  return request({
    url:'drying/chart',
    method:'get',
    params
  });
}
// 获取顶部栏和两个球状数据
export function dryingstore(params) {
  return request({
    url:'/drying/store',
    method:'get',
    params
  });
}
//获取订单情况
export function dryingorderInfo(params) {
  return request({
    url:'drying/orderInfo',
    method:'get',
    params
  });
}
// 获取已完成订单
export function getorderInfo(params) {
  return request({
    url:'drying/orderOver',
    method:'get',
    params
  });
}
// 烘干中心厂区环境以及烘干服务，
export function getenvironment(params) {
  return request({
    url:'drying/environment',
    method:'get',
    params
  });
}
// 烘干塔情况
export function getcondition(params) {
  return request({
    url:'drying/condition',
    method:'get',
    params
  });
}

// 获取全部烘干塔
export function getallTemperature(params) {
  return request({
    url:'drying/allTemperature',
    method:'get',
    params
  });
}
// 根据烘干塔名字获取
export function gettemperature(params) {
  return request({
    url:'/drying/temperature',
    method:'get',
    params
  });
}

// 注水图
export function dryingPowerData(params) {
  return request({
    url:'/drying/dryingPowerData',
    method:'get',
    params
  });
}
// 报警信息
export function getAlarm(params) {
  return request({
    url:'/drying/getAlarm',
    method:'get',
    params
  });
}

export function getStatusNum(params) {
  return request({
    url:'/drying/getStatusNum',
    method:'get',
    params
  });
}
export function getEnvironmentDetail(params) {
  return request({
    url:'/drying/getEnvironmentDetail',
    method:'get',
    params
  });
}

export function selectAllByPage(params) {
  return request({
    url:'drying/selectAllByPage',
    method:'get',
    params
  });
}

export function listDryer(params) {
  return request({
    url:'/drying/listDryer',
    method:'get',
    params
  });
}
// 查询加工基础表信息
export function listProcess(params) {
  return request({
    url:'/process/listProcess',
    method:'get',
    params
  });
}
// 农业科普、农情教育、研学记录接口
export function consult(params) {
  return request({
    url:'/popularize/consult',
    method:'get',
    params
  });
}

export function learnCount(params) {
  return request({
    url:'/popularize/learnCount',
    method:'get',
    params
  });
}
export function getTypeNum(params) {
  return request({
    url:'agricultureResearch/getTypeNum',
    method:'get',
    params
  });
}
export function research(params) {
  return request({
    url:'agricultureResearch/get',
    method:'get',
    params
  });
}
export function ResearchOrder(params) {
  return request({
    url:'agricultureResearchOrder/get',
    method:'get',
    params
  });
}
export function OrdergetTypeNum(params) {
  return request({
    url:'agricultureResearchOrder/getTypeNum',
    method:'get',
    params
  });
}




