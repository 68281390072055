<template>
  <div class="middle-navList">
    <div class="middle-navList-item">
      <span class="navList-item-title">加工能力</span>
      <div class="navList-item-desc">
        {{ NavObj.processPower }}吨/天
      </div>
    </div>
    <div class="middle-navList-item">
      <span class="navList-item-title">当年加工数量</span>
      <div class="navList-item-desc">
        {{ NavObj.dryingCountYear }}吨
      </div>
    </div>
    <div class="middle-navList-item">
      <span class="navList-item-title">待加工数量</span>
      <div class="navList-item-desc">
        {{ NavObj.processWait||0 }}吨
      </div>
    </div>
  </div>
</template>

<script>
import { getProcesspower } from '../../../../api/processApi/process';
export default {
  // props: {
  //   Option: {
  //     type: Array,
  //     require: true,
  //     default: () => { }
  //   }
  // },
  // watch: {
  //   Option: {
  //     deep: true,
  //     handler(newValue) {
  //       this.navList = [],
  //         newValue.forEach(element => {
  //           this.navList.push(element);
  //         });
  //     }
  //   }
  // },
  data() {
    return {
      NavObj: {},
    };
  },
  mounted() {
    this.getprocesspower();

  },
  methods: {
    // 获取导航栏接口
    getprocesspower() {
      let name =`${ localStorage.getItem('address') }加工中心`;
      getProcesspower(name).then(res => {
        this.NavObj = res.data;
      }).catch(err => {
        console.log(err);
      });
    }
  }
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../../assets/font/np4SotSdlbui.woff);
}

.middle-navList {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .middle-navList-item {
    width: companyW(208vw);
    height: 100%;
    position: relative;

    .navList-item-title {
      position: absolute;
      top: companyH(-7vh);
      left: 50%;
      transform: translate(-50%);
      height: companyH(16vh);
      font-size: companyH(16vh);
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: companyH(16vh);
    }

    .navList-item-desc {
      width: 100%;
      text-align: center;
      height: companyH(65vh);
      // background-image: url(../../../assets//drying-page/nav_bgc.png);
      background-image: url("../../../../assets/process/powerBox.png");
      background-repeat: no-repeat;
      background-size: contain;
      font-size: companyH(26vh);
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: companyH(65vh);
    }
  }
}
</style>
