<template>
  <div class="module-title">
     <div class="module-title-desc">
        {{title}}
     </div>
  </div>
</template>

<script>
export default {
  props:{
    title:{
      type:String,
      require:true
    }
  }
};
</script>

<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
@font-face {
    font-family: Source Han Sans CN;
    src: url(../../../../assets/font/np4SotSdlbui.woff);
  }
.module-title{
  position: absolute;
  top: companyH(-38vh);
  left: companyW(-19vw);
  width: companyW(546vw);
  height: companyH(109vh);
  background-image: url(../../../../assets/process/module-title.png);
  background-size: cover;
  .module-title-desc{
    position: absolute;
    left: companyW(53vw);
    bottom: companyH(45vh);
    width: companyW(126vw);
    height: companyH(18vh);
    font-size: companyW(20vw);
    font-family: Source Han Sans CN;
    font-weight: 700;
    font-style: italic;
    color: #FFFFFF;
    line-height: companyH(15vh);
    text-shadow: 0px 0px 9px rgba(32,22,0,0.45);
  }
}
</style>
