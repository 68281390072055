<template>
  <div class="dry-power">
    <moduleTitle title="厂区环境监测"></moduleTitle>
 <div style="display: flex;">
  <div class="left">
      <div class="temperature">
        <div class="imgBox"></div>
        <div class="txt">
          <div>{{ airTemperature }}°C</div>
          <span>空气温度</span>
        </div>
      </div>
      <div class="temperature humidness">
        <div class="imgBox2">
          <img src="../../../assets/process-num/power.png" alt="" />
        </div>
        <div class="txt">
          <div>{{ airDampness }}%</div>
          <span>空气湿度</span>
        </div>
      </div>
    </div>
    <div class="right">
      <!-- <Echart :option="factory"></Echart> -->
      <Echart :option="option"></Echart>
      <div class="fenchen">粉尘浓度</div>
    </div>
 </div>
 <img src="../../../assets/drying-page/xuxian.png" class="xuxian" />
 <!-- <div id="jgassessment" class="tubiao"></div> -->

  </div>
</template>

<script>
import moduleTitle from '../process/components/moduleTitle.vue'
import Echart from '@/components/echarts/index.vue';
import option from './config/factory.js';
import { data } from './config/factory.js'
import { getProcessEnvironment,getEnvironmentDetail } from '../../../api/processApi/process';
export default {
  components: {
    moduleTitle,
    Echart
  },
  data() {
    return {

      data,
      option,
      // 空气湿度
      airDampness: '',
      // 空气温度
      airTemperature: '',
      // 粉尘浓度
      dustLoading: '',
      data1: [],
      data2: [],
      timedata: [],

    };
  },
  mounted() {
    this.getprocessenvironment()
    this.tubiaodata();
  

  },
  methods: {
    getprocessenvironment() {
      let name = '中诚加工厂区'
      getProcessEnvironment(name).then(res => {
        console.log(res);
        this.airDampness = res.data.airDampness
        this.airTemperature = res.data.airTemperature
        this.dustLoading = res.data.dustLoading
        this.option.series[2].detail.formatter = [ `{name|${this.dustLoading}}`,`{b|mg/m³}`].join('\n');
        // this.data[0].value = this.dustLoading
        this.data[0] = { 'title': '', 'sub_title': `${this.dustLoading}`, value: this.dustLoading, 'min': 0, 'max': 50 }
        console.log(this.data[0], 111111);
        // 处理最大值及最小值
        if (!this.data[0].min) this.data[0].min = 0;
        if (!this.data[0].max) this.data[0].max = this.data[0].value / 0.8 + Math.random(0, parseInt(this.data[0].value * 0.2)) + 1;

        // 获取比率
        let rate = Math.round(this.data[0].value / this.data[0].max * 10000) / 100;
        console.log(rate);
        // 如果最大值大于100，则转换为百分比
        if (this.data[0].max > 100) {
          this.data[0].max = 100;
          this.data[0].min = 0;
        }
        this.option.series[2].axisLine = {
          show: true,
          lineStyle: {
            width: 10,
            color: [
              [
                rate / 100,
                " rgba(80, 192, 255, 1)"
              ],
              [1, 'rgba(69, 50, 28,.1)']
            ]
          },
        }


      }).catch(err => {
        console.log(err);
      })
    },
    tubiaodata() {
      getEnvironmentDetail({})
        .then((res) => {
          this.data1 = res.data.map((item) => {
            return item.airDampness;
          });
          this.data2 = res.data.map((item) => {
            return item.airTemperature;
          });
          this.timedata = res.data.map((item) => {
            return item.timePoint;
          });
          this.gettubiao();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gettubiao() {
      const myChart = this.$echarts.init(document.getElementById("jgassessment"));
      myChart.setOption({
        xAxis: {
          type: "category",
          data: this.timedata,

          splitLine: {
            show: false,
          },
          axisLabel: {
            lineStyle: {
              color: "#BAC6D0", //x轴的颜色
              width: 8, //轴线的宽度
            },
            //修改坐标系字体颜色
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        color: ["#FF6535", "#3DC4F6"],
        legend: {
          data: ["空气温度", "空气湿度"],
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "9%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          // show: false,
          // axisLine: {
          //   show: false,
          // },
          // axisTick: {
          //   show: false,
          // },
          axisLabel: {
          //修改坐标系字体颜色
          show: true,
          textStyle: {
            color: "#fff",
          },
        },
        },
        series: [
          {
            name: "空气温度",
            data: this.data1,
            type: "line",
            lineStyle: {
              color: "#FF6535",
            },
          },
          {
            name: "空气湿度",
            data: this.data2,
            type: "line",
            lineStyle: {
              color: "#3DC4F6",
            },
          },
        ],
      });
    },
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dry-power {
  
  position: relative;
  width: companyW(482vw);
  height: companyH(280vh);
  border: companyW(1vw) solid;

  border-image: linear-gradient(0deg, #7388C4, #7388C4) 10 10;

  .left {
    .temperature {
      display: flex;
      width: companyW(206vw);
      height: companyH(93vh);
      border-radius: companyW(5vw);
      border: companyW(1vw) solid #7388C4;
      margin-top: companyH(43vh);
      margin-left: companyW(25vw);
      background: transparent;
      border-radius: companyW(10vw);

      .imgBox {
        margin-top: companyH(10vh);
        margin-left: companyW(10vw);
        width: companyW(70vw);
        height: companyH(73vh);
        background-image: url(../../../assets/process-num/powerD.png);
        background-repeat: no-repeat;
        background-size: contain;
      }

      .txt {
        margin-top: companyH(26vh);
        margin-left: companyW(29vw);
        width: companyW(56vw);
        height: companyH(42vh);

        div {
          font-size: companyW(24vw);
          font-family: Source Han Sans SC;
          font-weight: 500;
          color: #C5DCF3;
        }

        span {
          margin-top: companyH(10vh);

          font-size: companyW(14vw);
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }

    .humidness {
      margin-top: companyH(18vh);

      .imgBox2 {
        margin-top: companyH(10vh);
        margin-left: companyW(10vw);
        width: companyW(70vw);
        height: companyH(73vh);

        img {
          width: 100%;
          height: 100%;
          background-size: contain;
        }
      }
    }
  }

  .right {
    width: companyW(203vw);
    height: companyH(203vh);

    margin-top: companyH(43vh);
    margin-left: companyW(26vw);
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  }
}

.dry-power::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 20, 36, 0.4);

  width: 100%;
  height: 100%;
  z-index: -1;
  /* 将伪元素放在父元素底部 */
  // opacity: 0.74;

}

.fenchen{
  color: #fff;
  font-size:companyW(22vw);
  margin-top:5px;
font-weight: bold;
}
.tubiao {
  width:90%;
  height: companyH(330vh);
  margin: 0 auto;
  margin-top: companyH(20vh);
}
.xuxian {
  width: companyW(438vw);
  height: companyH(2vh);
}
</style>
