<template>
  <div class="Right">
    <moduleTitle title="加工机情况"></moduleTitle>
    <div class="upperright">
      <div class="dryingTower">
        <div
          v-for="(item, index) in tableList"
          :key="index"
          @click="pointTower(item.id, index, item.machineName)"
          class="bigBox"
        >
          <div>
            <img
              :src="
                tupian != index
                  ? require('../../../../assets/process-num/process-white.png')
                  : require('../../../../assets/process-num/process-blue-point.png')
              "
              :class="tupian != index ? 'img1' : 'img2'"
            />
          </div>
          <div
            :style="{ color: tupian != index ? '#fff' : '#7388C4' }"
            class="towerName"
          >
            {{ item.machineName }}
          </div>
        </div>
      </div>
      <img src="../../../../assets/drying-page/xuxian.png" class="xuxian" />
      <div class="hgtopBox">
        <div class="smallBox">
          <img
            src="../../../../assets/process-num/qunliao.png"
            class="imgBox"
          />
          <p class="nameBox">订单业主：</p>
          <p>{{ currentData.farmerName }}</p>
        </div>
        <div class="smallBox">
          <img
            src="../../../../assets/process-num/qunliao.png"
            class="imgBox"
          />
          <p class="nameBox">谷物类型：</p>
          <p>{{ currentData.processWeight }}</p>
        </div>
        <div class="smallBox">
          <img
            src="../../../../assets/process-num/jiankong.png"
            class="imgBox"
          />
          <p class="nameBox">加工重量：</p>
          <p>{{ currentData.processWeight }}KG</p>
        </div>

        <div class="smallBox" style="width: 100%">
          <img src="../../../../assets/process-num/time.png" class="imgBox" />
          <p class="nameBox">开始时间：</p>
          <p>{{ currentData.beginTime }}</p>
        </div>
      </div>
      <img src="../../../../assets/drying-page/xuxian.png" class="xuxian" />
      <div class="hgtopBox">
        <div class="smallBox">
          <img src="../../../../assets/process-num/jingdu.png" class="imgBox" />
          <p class="nameBox">设定精度：</p>
          <p>{{ currentData.designPrecision }}%</p>
        </div>

        <div class="smallBox">
          <img
            src="../../../../assets/process-num/jiankong.png"
            class="imgBox"
          />
          <p class="nameBox">已出米量：</p>
          <p>{{ currentData.riceCount }}KG</p>
        </div>
        <div class="smallBox">
          <img src="../../../../assets/process-num/time.png" class="imgBox" />
          <p class="nameBox">已加工时长：</p>
          <p>{{ currentData.processAlreadyTime }}</p>
        </div>
        <div class="smallBox" style="width: 100%">
          <img src="../../../../assets/process-num/time.png" class="imgBox" />
          <p class="nameBox">预计完成：</p>
          <p>{{ currentData.predictTime }}</p>
        </div>
      </div>
      <img src="../../../../assets/drying-page/xuxian.png" class="xuxian" />
      <div id="jiaassessmente" class="tubiao"></div>
    </div>
  </div>
</template>

<script>
import moduleTitle from "../../process/components/moduleTitle.vue";
import {
  getProcessAllCondition,
  getProcessTemperature,
} from "../../../../api/processApi/process";
export default {
  components: {
    moduleTitle,
  },
  data() {
    return {
      tupian: 0,
      currentID: "",
      currentData: "",
      flag: false,
      tableList: [],
      data1: [],
      data2: [],
      data3: [],
      timedata: [],
    };
  },
  mounted() {
    this.getallcondition();
  },

  methods: {
    // 调用加工机接口
    getallcondition() {
      getProcessAllCondition()
        .then((res) => {
          this.tableList = res.data;
          this.currentData = res.data[0];
          getProcessTemperature({
            id: res.data[0].id,
          })
            .then((res) => {
              console.log(res);
              this.timedata = res.data.map((item) => {
                return item.timePoint;
              });
              this.data1 = res.data.map((item) => {
                return item.airInletTemperature;
              });
              this.data2 = res.data.map((item) => {
                return item.airOutletTemperature;
              });
              this.data3 = res.data.map((item) => {
                return item.towerTemperature;
              });
              this.gettubiao();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    pointTower(id, index, name) {
      this.tupian = index;
      this.currentData = this.tableList.find((item) => item.id == id);
      getProcessTemperature({
        id: id,
      })
        .then((res) => {
          this.timedata = res.data.map((item) => {
            return item.timePoint;
          });
          this.data1 = res.data.map((item) => {
            return item.airInletTemperature;
          });
          this.data2 = res.data.map((item) => {
            return item.airOutletTemperature;
          });
          this.data3 = res.data.map((item) => {
            return item.towerTemperature;
          });
          this.gettubiao();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gettubiao() {
      const myChart = this.$echarts.init(
        document.getElementById("jiaassessmente")
      );
      myChart.setOption({
        title: {},
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["最低温度", "最高温度", "平均温度"],
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          left: "9%",
          right: "12%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.timedata,
          axisLabel: {
            lineStyle: {
              color: "#BAC6D0", //x轴的颜色
              width: 8, //轴线的宽度
            },
            //修改坐标系字体颜色
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        color: ["#FF6535", "#3DC4F6", "#a0687a"],

        yAxis: {
          type: "value",
          axisLabel: {
            //修改坐标系字体颜色
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        series: [
          {
            name: "最低温度",
            type: "line",
            stack: "Total",
            data: this.data1,
          },
          {
            name: "最高温度",
            type: "line",
            stack: "Total",
            data: this.data2,
          },
          {
            name: "平均温度",
            type: "line",
            stack: "Total",
            data: this.data3,
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  font-size: companyW(18vw);
  box-sizing: border-box;
  color: #fff;
}

.img {
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
}

.Right {
  margin-right: companyW(20vw);
  position: relative;
  // overflow: auto;
  overflow-x: hidden;
  width: companyW(482vw);

  .upperright {
    // margin-top:companyH(21vh) ;
    box-sizing: border-box;
    height: 100%;
    position: relative;

    width: companyW(482vw);
    border: companyW(1vw) solid;
    border: 1px solid;
    border-image: linear-gradient(0deg, #7388c4, #7388c4) 10 10;

    .dryingTower {
      display: flex;
      // justify-content: space-evenly;
      align-items: center;
      width: 100%;
      height: companyH(150vh);
      overflow-x: auto;
      margin-top: companyH(30vh);
    }
  }
}

.upperright::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 20, 36, 0.4);

  width: 100%;
  height: 100%;
  z-index: -1;
  /* 将伪元素放在父元素底部 */
  // opacity: 0.74;
}
::-webkit-scrollbar {
  // 纵向滚动条和横向滚动条宽度
  width: companyW(10vw);
  height: companyH(10vh);
}
::-webkit-scrollbar-thumb {
  // 滚动条背景条样式
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #fff;
}
::-webkit-scrollbar-track {
  // 滚动条按钮样式
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  background: #5b5f63;
}
.bigBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  width: companyW(80vw);

  .img1 {
    width: companyW(40vw);
    height: companyH(47vh);
  }
  .img2 {
    width: companyW(52vw);
    height: companyH(56vh);
  }
  .towerName {
    font-size: companyW(16vw);
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    margin-top: companyH(20vh);
    width: companyW(80vw);
    text-align: center;
  }
}
.xuxian {
  width: companyW(438vw);
  height: companyH(2vh);
}
.hgtopBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: companyW(10vw);
  margin-top: companyH(26vh);
}
.smallBox {
  display: flex;
  color: #fff;
  width: 50%;
  align-items: center;
  margin-bottom: companyH(26vh);
  .nameBox {
    margin-left: companyW(10vw);
  }
  .imgBox {
    width: companyW(32vw);
    height: companyH(32vh);
  }
}
.tubiao {
  width: 90%;
  height: companyH(290vh);
  margin: 0 auto;
  margin-top: companyH(20vh);
}
</style>
